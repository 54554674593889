* { box-sizing: border-box; }
html, body, #root {
	height:100%;
	margin: 0;
  scroll-behavior: auto;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: auto;
}

a { 
  text-decoration: none;
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat/montserrat-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/montserrat/montserrat-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat/montserrat-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat/montserrat-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat/montserrat-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat/montserrat-v23-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat/montserrat-v23-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/montserrat/montserrat-v23-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat/montserrat-v23-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat/montserrat-v23-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat/montserrat-v23-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat/montserrat-v23-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/montserrat/montserrat-v23-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/montserrat/montserrat-v23-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat/montserrat-v23-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat/montserrat-v23-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat/montserrat-v23-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat/montserrat-v23-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat/montserrat-v23-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/montserrat/montserrat-v23-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat/montserrat-v23-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat/montserrat-v23-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat/montserrat-v23-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat/montserrat-v23-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* markdown styles */
.markdown {
  word-break: break-word;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.markdown.alignCenter {
  text-align: center;
}

.markdown ol, .markdown ul {
  list-style-position: outside;
  padding: 0 0 10px 0;
  padding-inline-start: 20px;
  margin: 0;
}

.markdown table {
  border: 1px solid black;
  margin-bottom: 20px;
  width: 100%;
}

.markdown table thead tr th {
  text-align: left;
}

.markdown strong {
  font-weight: 700;
}

.markdown em {
  font-style: italic;
}

.markdown del {
  font-style: italic;
  text-decoration: line-through;
}

.markdown blockquote {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.markdown img {
  height: auto;
  max-width: 100%;
  width: auto;
}

.markdown h1 {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.125rem;
  margin: 0;
  padding: 0;
}

.markdown h2 {
  color: #000000;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin: 0;
  padding: 0;
}

.markdown h3, .markdown h4, .markdown h5, .markdown h6 {
  color: #000000;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin: 0 0 5px 0;
  padding: 0;
}

/* Custom margins for headers inside of pageBuilder */
.pageBuilder .markdown h1 {
  margin: 0 0 10px 0;
}

.pageBuilder .markdown h2 {
  margin: 0 0 7px 0;
}

.pageBuilder .markdown h3, .pageBuilder .markdown h4,
.pageBuilder .markdown h5, .pageBuilder .markdown h6 {
  margin: 0 0 5px 0;
}

.markdown p {
  padding: 0 0 10px 0;
  margin: 0;
}

.flipcard .markdown p {
  font-size: 15px;
  line-height: 1.3rem;
}

@media (min-width: 768px) {
  .markdown h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }

  .markdown h2 {
    font-size: 1.5rem;
    line-height: 1.95rem;
  }

  .markdown h3, .markdown h4, .markdown h5, .markdown h6 {
    font-size: 1.375rem;
    line-height: 1.575rem;
  }
}
